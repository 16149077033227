<template>
  <div
    class="text-subtitle-1 pa-1 info-container"
  >
    Максимальный размер одного файла <span class="blue--text">{{ maxFileSize }}</span>
    <p
      v-if="isAllowedTypes"
      class="ma-0"
    > Формат доступных к загрузке документов:
      <span class="blue--text">{{ types }}</span></p>
  </div>
</template>

<script>
export default {
  name: 'Limit',
  props: {
    maxFileSize: String,
    isAllowedTypes: {
      type: Boolean,
      default: true
    },
    types: {
      type: String,
      default: 'PDF, DOC, DOCX, XLS, XLSX'
    }
  }
}
</script>

<style scoped>
  .info-container {
    border-radius: 5px;
    width: auto;
    max-width: 600px;
  }
</style>