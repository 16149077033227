import { cleanClone } from '@/components/utils/common'
import { baseUrl, maxFileSize } from '@/config/common'

export default {
  props: {
    valueDocs: {
      type: Array
    },
    acceptTypes: {
      type: String,
      default: 'application/pdf'
    },
    modelName: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false
    },
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor"
    multiple: { Boolean, default: true }, //(нужен для отключения multiple) пропс передается из Appeal/DetailView, как false, в самом BaseUploaderFile стоит по умолчанию true
    idUpload: { String, default: 'docs' }, //использовать, если на одной странице нужно загружать документы в разные модели
    value: Object,
    nameObjectDoc: String,
    withDescription: Boolean
  },
  data () {
    return {
      detail: this.value,
      maxFileSize,
      baseUrl,
      isDeleteDialog: false,
      deleteId: null,
      files: !this.nameObjectDoc ? this.valueDocs || [] : this.value?.properties[this.nameObjectDoc] || []
    }
  },
  methods: {
    async onLoadFile (uploadFile) {
      this.isLoading = true
      if (uploadFile.length > 0 && this.validationFile(uploadFile)) {
        const formData = new FormData()
        for (let i = 0; i < uploadFile.length; i++) {
          formData.append(`files[${i}]`, uploadFile[i])
        }
        formData.append('modelName', this.modelName)
        formData.append('modelId', String(this.modelId))
        const response = await this.$store.dispatch('api/post', { url: '/file', data: formData }, { root: true })
        if (response.success) {
          if (this.multiple === false) {
            this.files = [{
              id: this.modelId,
              file: response.data[0]
            }]
          } else {
            this.files = this.files.concat(this.addEditModeAttr(response.data))
            this.nameObjectDoc && this.$set(this.detail.properties, this.nameObjectDoc, this.files)
          }
          await this.$store.dispatch('systemMessages/success', 'Файл успешно загружено', { root: true })
        }
      } else if (uploadFile) {
        const response = await this.$store.dispatch('api/post', {
          url: '/file',
          data: { data: uploadFile }
        }, { root: true })
        if (response.success) {
          await this.$store.dispatch('systemMessages/success', 'Описание добавлено', { root: true })
        }
      }
      if (!this.nameObjectDoc) {
        this.$emit('input', cleanClone(this.files))
      } else {
        this.$emit('input', cleanClone(this.detail))
      }

      this.isLoading = false
      return true
    },
    handleEditDescr (File) {
      if (File.isEditModeDescription) {
        File.file.description_date = new Date().toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
        this.onLoadFile(File.file)
        File.isEditModeDescription = !File.isEditModeDescription
      } else {
        File.isEditModeDescription = !File.isEditModeDescription
      }
    },
    localDescr (item) {
      return item.description ? item.description : 'Добавить описание'
    },
    handleDelete (id) {
      this.deleteId = id
      this.isDeleteDialog = true
    },
    async deleteFile () {
      this.isLoading = true
      const response = await this.$store.dispatch('api/delete', { url: '/file?id=' + this.deleteId }, { root: true })
      if (response.success) {
        this.files = this.files.filter((item) => item.file.id !== this.deleteId)
        this.nameObjectDoc && this.$set(this.detail.properties, this.nameObjectDoc, this.files)
      }
      if (!this.nameObjectDoc) {
        this.$emit('input', cleanClone(this.files))
      } else {
        this.$emit('input', cleanClone(this.detail))
      }
      this.isLoading = false
      this.isDeleteDialog = false
      this.deleteId = null
      return true
    },
    validationFile (value) {
      let isError = true
      if (value) {
        value.map((item) => {
          if (item?.size > maxFileSize) {
            isError = false
            this.$store.dispatch(
              'systemMessages/error',
              `Размер файла должен быть не больше ${this.bytesToSize(maxFileSize, ' ')}: ` + item.name,
              { root: true }
            )
          }
        })
      } else {
        isError = false
      }
      return isError
    },
    getPath (item) {
      return baseUrl + 'files/' + item.file.path + item.file.file_name
    },
    addEditModeAttr (array) {
      return array = array.map(item => {
        return {
          ...item,
          isEditModeDescription: false
        }
      })
    }
  },
  computed: {
    title () {
      let title = 'Загрузить файл'
      const isDocs = this.nameObjectDoc ? this.detail?.properties[this.nameObjectDoc]?.length : this.valueDocs?.length
      if (this.multiple === false && isDocs) {
        title = 'Обновить файл'
      }
      return title
    }
  },
  mounted () {
    this.files = this.addEditModeAttr(this.files)
  }
}